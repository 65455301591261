<template>
  <div class="Advert_I">
    <div class="headNavs">
      <div class="headTilte">广告位</div>
      <div class="headDetails">平台所有地方的广告管理</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="NavLiDelte" @click="DelMore()"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入订单编号">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelect">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="ID" width="55" align="center"></el-table-column>
        <el-table-column align="center" prop="title" label="标题"></el-table-column>
        <el-table-column align="center" label="图片">
          <template #default="scope">
            <el-image class="Toper"
                      :src="scope.row.full_image" alt=""
                      :preview-src-list="[scope.row.full_image]"
                      :initial-index="0"
                      :hide-on-click-modal=true
                      fit="cover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="link_type_text" label="跳转类型"></el-table-column>
        <el-table-column align="center" prop="status" label="状态">
          <template #default="scope">
            <el-switch
                v-model="scope.row.status"
                :active-value=1
                :inactive-value=0
                @change="handleChange"
            />
          </template>
        </el-table-column>
        <el-table-column align="center" prop="alias" label="别名"></el-table-column>
        <el-table-column align="center" prop="createtime" label="创建时间"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.$index,scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.$index, scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>标题</div>
            <div class="EditNewFormItemIpt">
              <el-input type="text" v-model="Row.title" placeholder="请输入标题" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>别名</div>
            <div class="EditNewFormItemIpt">
              <el-input type="text" v-model="Row.alias" placeholder="请填写别名" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>跳转链接</div>
            <div class="EditNewFormItemIpt">
              <el-input type="text" v-model="Row.link_url" placeholder="请输入跳转链接" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>小程序APPID</div>
            <div class="EditNewFormItemIpt">
              <el-input type="text" v-model="Row.appid" placeholder="请输入小程序APPID" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>跳转类型</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.link_type" placeholder="请选择跳转类型">
                <el-option v-for="(item,key) in LinkTypeList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择是否显示">
                <el-option v-for="(item, key) in StatusList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">图片</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <el-upload
                  ref="upload-avatar"
                  list-type="picture-card"
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :file-list="fileList"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-preview="handlePreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "index",
  data() {
    return {
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      StatusList: [
        "隐藏",
        "显示"
      ],
      titleMath:'',
      //搜索参数
      search: {
        keyword: "",
      },
      page: {
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      Row: {
        title:"",
        link_type: "",
        image: "",
        link_url:"",
        appid:"",
        alias:"",
        status: 1,
      },
      fileList:[],
      addPanel: false,
      editPanel: false,
      editId: 0,
    }
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable();

    axios.get('/admin/general/advert/statisticsColumns', {   // 设置选项
    }).then(function (res) {
      if (res.data.code == 0) {
        alert('请求失败');
      } else {
        that.LinkTypeList = res.data.data.link_type;
      }
    })
  },
  methods: {

    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/advert/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        //console.log("数据加载")
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
        //console.log(that.tableData);
      })
    },

    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          keyword: "",
        };
      }
      that.loadTable();
    },
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/general/advert/add',
          data: {
            row: that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    BtnEdit() {
      var that = this
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/general/advert/edit',
          data: {
            id: that.editId,
            row: that.Row
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
        })
      }
    },
    showAdd() {
      var that = this
      that.addPanel = true;
      that.titleMath = '添加广告位'
      that.Row = {
        title:"",
        link_type: "",
        image: "",
        link_url:"",
        appid:"",
        alias:"",
        status: 1,
      };
      that.fileList = [];
    },
    HandleEdit(e, data) {
      var that = this
      that.editPanel = true;
      that.editId = data.id
      that.titleMath = '编辑广告位'
      axios.get('/admin/general/advert/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row = {
            title: res.data.data.row.title,
            link_type: res.data.data.row.link_type,
            image: res.data.data.row.image,
            link_url:res.data.data.row.link_url,
            appid:res.data.data.row.appid,
            status: res.data.data.row.status,
            alias: res.data.data.row.alias,
          }
          //图片
          let fileArr = [];
          let fileObj = {};
          fileObj.name = "file";
          fileObj.url = res.data.data.row.full_image;
          fileArr.push(fileObj);
          that.fileList = fileArr
        }
      })
    },
    Close() {
      var that = this
      that.addPanel = false;
      that.editPanel = false;
    },
    HandleDel(e, data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        that.delTable(arr);
      })
    },
    DelMore() {
      var that = this
      var arr = that.multipleSelection_id
      if (that.multipleSelection_id == null) {      //批量删除
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
        }).then(() => {
          //console.log(arr)
          that.delTable(arr);
        })
      }
    },
    delTable(arr) {
      var that = this
      axios({
        method: 'delete',
        url: '/admin/general/advert/del',
        data: {
          ids: arr
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.loadTable();
        }
      })
    },
    //图片上传成功
    handleSuccess(e) {
      var that = this
      that.Row.image = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-avatar"].clearFiles()
      this.$refs["upload-avatar"].handleStart(files[0])
      this.$refs["upload-avatar"].submit();
    },
    handlePreview(file) {
      window.open(file.url);
    },
    //开关
    handleChange(e) {
      console.log(e)
    },
    //勾选
    handleSelect(e) {
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
      } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          //主键ID
          arr.push(element.id)
          acc.push(element)
        }
        that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;

      return y + "-" + MM + "-" + d
    },
  }
}
</script>
